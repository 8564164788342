import { getUser } from 'contexts/JWTContext';
import Cookies from '../utils/Cookies';

export const CONFIG = {
    url: process.env.REACT_APP_BACKEND_API,
};

export function getBearerToken() {
    const serviceToken = localStorage.getItem('serviceToken');
    return serviceToken;
}

function getUserId() {
    const userData = getUser();
    return userData && userData._id ? userData._id : null;
}

function getTenantId() {
    const tenantData = JSON.parse(localStorage.getItem('tenant'));
    return tenantData && tenantData._id ? tenantData._id : null;
}

function setBearerToken(token) {
    localStorage.setItem('token', token);
    Cookies.setCookie('token', token)
}

function clearBearerToken() {
    localStorage.removeItem('token');
    Cookies.deleteCookie('token');
}

export default class api {

    static async count(entity, filter) {
        return filter ? 
        await getContent(`api_me/${entity}/count?userId=${getUserId()}&${filter}`)
        :
        await getContent(`api_me/${entity}/count`);
    }

    static async findOne(entity, id) {
        return await getContent(`api_me/${entity}/${id}/get?userId=${getUserId()}`); 
    }
    
    static async find(entity, filter, tenantId) {
        return filter ? 
        await getContent(`api_me/${entity}?userId=${getUserId()}&${filter}`, tenantId)
        :
        await getContent(`api_me/${entity}?userId=${getUserId()}`, tenantId);
    }

    static async findSearchBy(entity, searchBy, filter = '') {
        return await getContent(`api_me/${entity}?userId=${getUserId()}&searchBy=${encodeURIComponent(searchBy)}&filter=${filter}`);
    }

    static async findOneWithPath(entity, path, entityId) {
        //const companyId = await getCompanyId()
        return await getContent(`api_me/${entity}/${path}/${entityId}?userId=${getUserId()}`);
    }

    static async update(entity, Id, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}/${encodeURIComponent(Id)}/put?userId=${getUserId()}`, {
            method: 'PUT',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async post(entity, postData, path = '', tenantId) {
        const token = getBearerToken();

        const tenant_id = tenantId ? tenantId : getTenantId();
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}${path}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': tenant_id
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async patch(entity, Id, postData, path = '') {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}${path}/${encodeURIComponent(Id)}/patch?userId=${getUserId()}`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async patch1 (entity, operation, id, postData){
        const token = getBearerToken()
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}/${operation}/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
        })
        .then( (response) => response.json())
        .then(res => {
            return res
        })
        .catch(error => {
            return { error }
        })

        return result;
    }

    static async insert(entity, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}/create?userId=${getUserId()}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async attach(entity, key, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entity}/${encodeURIComponent(key)}/attach`, {
            method: 'PATCH',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async insertFormData(formId, postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api/formdata/${formId}/create`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async delete(entityName, entityId, path = 'delete', postData) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entityName}/${entityId}/${path}`, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }

    static async remove(entityName, entityId) {
        const token = getBearerToken()
         
        const result = fetch(`${process.env.REACT_APP_BACKEND_API}/api_me/${entityName}/${entityId}/delete`, {
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'tenantId': getTenantId()
            }
          })
            .then( (response) => response.json())
            .then(res => {
                return res
            })
            .catch(error => {
                return { error }
            })
        
        return result
    }
}

export async function getUserData(id) {
    const json = await getContent(`api/user/${id}`);

    return parseUser(json);
}

function parseUser(response) {
    return {
        _id: response._id,
        username: response.username,
        lastName: response.lastName,
        firstName: response.firstName,
        department: response.department,
        email: response.email,
        created: response.createdAt,
    };
}

export async function fetchBearerToken() {
    // Check if we have already a bearer token in local store.
    let token = getBearerToken();

    if (token) {
        return token;
    }

    const body = ``;

    // Get the bearer token. Ensure that we use a client id with readonly permissions.
    const response = await fetch(buildUrl('connect/token'), { 
        method: 'POST', 
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body 
    });

    if (!response.ok) {
        throw new Error(`Failed to retrieve token, got ${response.statusText}`);
    }

    const json = await response.json();

    token = json.access_token;

    // Cache the bearer token in the local store.
    setBearerToken(token);

    return token;
}

function getContent(url, tenantId) {
    return getContentInternal(url, true, tenantId);
}

async function getContentInternal(url, retry, tenantId) {
    // Fetch the bearer token.
    //const token = await fetchBearerToken(); // TODO: Token
    const token = getBearerToken()
    //console.log(' api token', token)
    const tenant_id = tenantId ? tenantId : getTenantId();

    const response = await fetch(buildUrl(url), {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'tenantId': tenant_id
        }
    });

    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            // If we get an error we clear the bearer token and retry the request.
            clearBearerToken();

            if (retry) {
                return getContentInternal(url);
            }
        }

        throw new Error(`Failed to retrieve content, got ${response.statusText}`);
    }

    return await response.json();
}

function buildUrl(url) {
    if (url.length > 0 && url.startsWith('/')) {
        url = url.substr(1);
    }

    const result = `${CONFIG.url}/${url}`;

    return result;
}