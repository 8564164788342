import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconDashboard, IconDeviceAnalytics, IconReportAnalytics, IconNotebook, IconMenu, IconDeviceTv, IconBrandFacebook,
    IconWorldWww 
} from '@tabler/icons';

// constant
const icons = { IconMenu, IconDashboard, IconDeviceAnalytics, IconReportAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'analytics',
            title: <FormattedMessage id="analytics" />,
            type: 'item',
            url: '/analytics',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'home',
            title: <FormattedMessage id="today" />,
            type: 'item',
            url: '/dashboard',
            icon: IconHome,
            breadcrumbs: false
        },
        {
            id: 'news_articles',
            title: <FormattedMessage id="news_articles" />,
            type: 'item',
            url: '/news_articles',
            icon: IconWorldWww,
            breadcrumbs: false
        },
        // {
        //     id: 'magazine_articles',
        //     title: <FormattedMessage id="magazine_articles" />,
        //     type: 'item',
        //     url: '/magazine_articles',
        //     icon: IconNotebook,
        //     breadcrumbs: false
        // },
        {
            id: 'newspaper_articles',
            title: <FormattedMessage id="newspaper_articles" />,
            type: 'item',
            url: '/newspaper_articles',
            icon: IconNotebook,
            breadcrumbs: false
        },
        {
            id: 'tvPrograms',
            title: <FormattedMessage id="tvPrograms" />,
            type: 'item',
            url: '/tv_programs',
            icon: IconDeviceTv,
            breadcrumbs: false
        },
        {
            id: 'social_posts',
            title: <FormattedMessage id="social_posts" />,
            type: 'item',
            url: '/social_posts',
            icon: IconBrandFacebook,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
